import React, { useState } from "react";
import UserProfile from "../layouts/UserProfile";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Profile = () => {

  const [playerMailID, setPlayerMailID] = useState(0);

  const { state } = useLocation();
  return (
    <Container fluid>
      <Row>
        <Col xs={12} xl={12}>
          <UserProfile playerId={state?.playerId} playerIntID={state?.playerIntID} playerMailID={playerMailID} setPlayerMailID={setPlayerMailID} />
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
