import React from "react";
import { FiSave } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { FaUndoAlt } from "react-icons/fa";
import IntuitaButton from "../Button/IntuitaButton";
import toast, { Toaster } from "react-hot-toast";

import "./confirmationModal.css";

const ConfirmationModal = ({ setShowConfirmationModal, titleText }) => {
  const notifySuccess = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);
  return (
    <div className="bg-white rounded-lg shadow-lg confirmationModal">
      {/* border border-gray-300 */}
      <Toaster />
      <div
        className="flex-column p-3"
        style={{ border: "3px solid var(--secondary-blue)" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-3">
          <span className="d-flex align-items-center justify-content-center saveIcon">
            {titleText === "Cancel Changes" ? (
              <FaUndoAlt
                className="p-1"
                size={24}
                color="var(--secondary-blue)"
              />
            ) : (
              <FiSave className="p-1" size={24} color="var(--secondary-blue)" />
            )}
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => setShowConfirmationModal(false)}
          >
            <AiOutlineClose size={20} />
          </span>
        </div>
        <h4 className="fw-bold">{titleText}</h4>
        <p>
          {titleText === "Save Changes"
            ? "Are you sure you want to save the changes you made to the configuration?"
            : "Cancel the changes made to the configuration?"}
        </p>
        <div className="d-flex justify-content-between">
          <IntuitaButton
            className="fw-bold bg-white text-black rounded"
            text={titleText === "Cancel Changes" ? "No" : "Cancel"}
            onClick={() => setShowConfirmationModal(false)}
          />
          <IntuitaButton
            text={titleText === "Cancel Changes" ? "Yes" : "Save Changes"}
            className="text-white rounded"
            onClick={() => {
              {
                titleText === "Cancel Changes"
                  ? notifyError("Changes Cancelled")
                  : notifySuccess("Changes Saved Successfully");
              }
              setTimeout(() => {
                setShowConfirmationModal(false);
              }, 1000);
            }}
            style={{
              background: "var(--secondary-blue)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
