import React, { useState } from "react";
import InputBar from "../shared/SearchBar/InputBar";
import { useLazyQuery as apolloLazyQuery } from "@apollo/client";
import { GET_PLAYER_ID_FROM_EMAIL_ADRESS } from "../../graphql/queries";

const ProfileHeader = ({ setPlayerMailID }) => {
  const [emailValue, setEmailValue] = useState("");

  const [getPlayerID, { loading, error, data }] = apolloLazyQuery(
    GET_PLAYER_ID_FROM_EMAIL_ADRESS,
    { variables: { emailAddress: emailValue }, fetchPolicy: "no-cache" }
  );
  //Put variables in function call

  const handleEmailChange = (e) => {
    setEmailValue(e.target.value);
  };
  //Jason.Marquardt@marvin.biz
  const submitEmailValue = (e) => {
    if (e.key === "Enter") {
      getPlayerID().then((response) => {
        setPlayerMailID(response.data.getPlayerIdFromEmailAddress.player_id);
      });
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-end mb-3">
        <InputBar
          placeholder="Search by Email"
          icon="search"
          className="w-25"
          onKeyPress={(e) => submitEmailValue(e)}
          onChange={handleEmailChange}
        />
      </div>
    </div>
  );
};

export default ProfileHeader;
