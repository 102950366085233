import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import nFormatter from "../../utils/numberFormatter";
import { profileTableData } from "../../data/mockTableData";
import "../../styles/component-style.css";
import { cloneDeep } from "lodash";

const ProfileActivity = ({ playerData }) => {

  const [playerWagersTransactions, setPlayerWagersTransactions] = useState([]);
  let playerWagersTransactionsTemp = [];

  const comparePlayerTransactionTimeStrings = (firstItem, secondItem) => {
    let firstItemDate = "";
    let secondItemDate = "";

    let firstItemMilisec = 0;
    let secondItemMilisec = 0;

    if (firstItem.TimeStamp)
    {
      firstItemDate = new Date(firstItem.TimeStamp);
    }
    else
    {
      firstItemDate = new Date(firstItem.Timestamp);
    }
    if (secondItem.TimeStamp) 
    {
      secondItemDate = new Date(secondItem.TimeStamp);
    }
    else
    {
      secondItemDate = new Date(secondItem.Timestamp);
    }
    firstItemMilisec = firstItemDate.getTime();
    secondItemMilisec = secondItemDate.getTime();


    if (firstItemMilisec < secondItemMilisec) {
      return 1;
    }
    if (firstItemMilisec > secondItemMilisec) {
      return -1;
    }
    return 0;
  };

  //subscription component mount
  useEffect(() => {
    if (playerData.length !== 0 && playerData !== undefined) 
    {
      let playerDataClone = cloneDeep(playerData);
      playerDataClone[0].financial_transactions.forEach((item) => {
        console.log(item);
        playerWagersTransactionsTemp = [...playerWagersTransactionsTemp, item];
      });
      playerDataClone[0].wagers.forEach((item) =>
      {
        console.log(item);
        playerWagersTransactionsTemp = [...playerWagersTransactionsTemp, item];
      });
      playerWagersTransactionsTemp.sort(comparePlayerTransactionTimeStrings);
      setPlayerWagersTransactions(playerWagersTransactionsTemp);
    }
  }, []);

  //component subscription update
  /* useEffect(() => {}, []); */

  return (
    <div className="mt-5 mb-3">
      <div
        className="component-container"
        style={{ height: "45vh" }}
      >
        <p style={{ color: "#a7adcb", fontSize: "14px", fontWeight: "700" }}>
          ACTIVITY
        </p>
        <div
          className="w-100 overflow-auto overflow-style"
          style={{ height: "90%" }}
        >
          <table className="w-100 m-0 text-white">
            <thead>
              <tr
                className="fw-bold"
                style={{ height: "3.5em", borderBottom: "1px solid #222535" }}
              >
                <td>DATE</td>
                <td>TIME</td>
                <td>EVENT</td>
                <td>COUNTRY</td>
                <td>GAME</td>
                <td>OUTCOME</td>
                <td>STAKE / AMOUNT</td>
                <td>BALANCE</td>
                <td>RISK</td>
              </tr>
            </thead>
            {playerData.length === 0 ? (
              <tbody>
                {profileTableData.map((item) => {
                  return (
                    <tr
                      style={{
                        height: "2.5em",
                        borderBottom: "1px solid #222535",
                        fontSize: "14px",
                      }}
                    >
                      <td>{item.date}</td>
                      <td>{item.time}</td>
                      <td>{item.event}</td>
                      <td>{item.country}</td>
                      <td>{item.game}</td>
                      <td>{item.outcome}</td>
                      <td>${item.stake}</td>
                      <td>${item.balance}</td>
                      <td>
                        <span style={item.riskStyle}>{item.risk}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {playerWagersTransactions.map((item) => {
                  let someDate = "";
                  let formattedDatePart = "";
                  let formattedDatePartHour = "";

                  if (item.TimeStamp) {
                    someDate = new Date(item.TimeStamp);
                    formattedDatePart = format(someDate, "LL-dd-yyyy");
                    formattedDatePartHour = format(someDate, "pp");
                  } else if (item.Timestamp) {
                    someDate = new Date(item.Timestamp);
                    formattedDatePart = format(someDate, "LL-dd-yyyy");
                    formattedDatePartHour = format(someDate, "pp");
                  }
                  return (
                    <tr
                      style={{
                        height: "2.5em",
                        borderBottom: "1px solid #222535",
                        fontSize: "14px",
                      }}
                    >
                      <td>{formattedDatePart || null}</td>
                      <td>{formattedDatePartHour || null}</td>
                      <td>{item.Tag}</td>
                      <td>{playerData[0].country_code}</td>
                      <td>{item.Game || null}</td>
                      <td>{item.Type}</td>
                      <td>
                        {item.Amount ? `$ ${nFormatter(item.Amount, 2)}` : null}
                      </td>
                      <td>
                        {item.PlayerBalance
                          ? ` $ ${nFormatter(item.PlayerBalance, 2)}`
                          : null || item.ResultingBalance
                          ? ` $ ${nFormatter(item.ResultingBalance, 2)}`
                          : null}
                      </td>
                      <td>{item.RiskScore || 0}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProfileActivity;
