import React from "react";
import FeatherIcon from "feather-icons-react";
import "../../styles/component-style.css";
import { cloneDeep } from "lodash";

const ProfileAndRiskInfo = ({ playerData }) => {

  /* const mockData = [
    {
      id: 1,
      title: "Lifetime Value",
      value: "$4,567",
      icon: "dollar-sign",
    },
    {
      id: 2,
      title: "Max Risk",
      value: "84",
      icon: "alert-triangle",
    },
    {
      id: 3,
      title: "Current Risk",
      value: "72",
      icon: "activity",
    },
  ]; */

  const findHighestRiskScore = () => {
    if (playerData.length === 0 || playerData === undefined) {
      return "";
    } else {
      let playerWagersTransactions = [];
      playerData.forEach((playerItem) => {
        playerItem.wagers.forEach((wagerItem) => {
          playerWagersTransactions = [...playerWagersTransactions, wagerItem];
        });
      });
      let highestRiskScore = 0;
      playerWagersTransactions.forEach((item) => {
        if (highestRiskScore < item.RiskScore) {
          highestRiskScore = item.RiskScore;
        }
      });
      return highestRiskScore;
    }
  };

  return (
    <div>
      <div className="my-3 component-container">
        {/* Profile Info */}
        <div className="d-flex flex-column mb-3">
          <span
            style={{ fontWeight: "700", fontSize: "14px", color: "#a7adcb" }}
          >
            PROFILE AND RISK INFO
          </span>
          <div className="mt-3 d-flex justify-content-between">
            <div className="d-flex">
              <img
                style={{ height: "80px", width: "80px" }}
                src="https://picsum.photos/200"
                alt="User profile"
              />
              {/* Name and Active */}
              <div className=" d-flex flex-column justify-content-between m-2">
                {playerData.length !== 0 ? (
                  <span className="text-white">
                    {playerData[0].first_name} {playerData[0].last_name}
                  </span>
                ) : (
                  <span className="text-white">John Doe</span>
                )}
                <div
                  className="d-flex align-items-center"
                  style={{
                    padding: "10px 12px 10px 4px",
                    backgroundColor: "rgba(111, 207, 151, 0.15)",
                    borderRadius: "24px",
                    gap: "4px",
                    height: "24px",
                    width: "76px",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      borderRadius: "50%",
                      backgroundColor: "#219653",
                    }}
                  ></div>
                  <span
                    style={{
                      color: "#219653",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Active
                  </span>
                </div>
              </div>
              {/* Name and Active */}
            </div>
            {/* TODO: Map thru the mock data and renders the results */}
            <div className="d-flex gap-3">
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#1D3B52",
                    border: "1px solid #222535",
                    borderRadius: "4px",
                    padding: "10px 12px",
                  }}
                >
                  <FeatherIcon
                    icon="dollar-sign"
                    style={{
                      color: "#2D9CDB",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </div>
                <div className="d-flex flex-column ms-3">
                  <span
                    className="fw-bold"
                    style={{
                      color: "#a7adcb",
                      fontSize: "14px",
                    }}
                  >
                    LIFETIME VALUE
                  </span>
                  {playerData.length !== 0 ? (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {playerData[0].metrics.CLV}
                    </span>
                  ) : (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      1000
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#1D3B52",
                    border: "1px solid #222535",
                    borderRadius: "4px",
                    padding: "10px 12px",
                  }}
                >
                  <FeatherIcon
                    icon="alert-triangle"
                    style={{
                      color: "#2D9CDB",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </div>
                <div className="d-flex flex-column ms-3">
                  <span
                    className="fw-bold"
                    style={{
                      color: "#a7adcb",
                      fontSize: "14px",
                    }}
                  >
                    MAX RISK
                  </span>
                  {playerData.length !== 0 ? (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {findHighestRiskScore()}
                    </span>
                  ) : (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      10
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    background: "#1D3B52",
                    border: "1px solid #222535",
                    borderRadius: "4px",
                    padding: "10px 12px",
                  }}
                >
                  <FeatherIcon
                    icon="activity"
                    style={{
                      color: "#2D9CDB",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                </div>
                <div className="d-flex flex-column ms-3">
                  <span
                    className="fw-bold"
                    style={{
                      color: "#a7adcb",
                      fontSize: "14px",
                    }}
                  >
                    CURRENT RISK
                  </span>
                  {playerData.length !== 0 ? (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {playerData[0].metrics.CurrentRiskScore}
                    </span>
                  ) : (
                    <span
                      className="text-white fw-bold"
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      10
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileAndRiskInfo;
