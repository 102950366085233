import React, { useState, useEffect } from "react";
import { GoSettings } from "react-icons/go";
import { cloneDeep } from "lodash";
import "../../styles/component-style.css";
import IntuitaButton from "../shared/Button/IntuitaButton";

const InterventionsHeader = ({ timeUnit, setTimeUnit, resetComponent }) => {
  const [activeBtn, setActiveBtn] = useState("");

  useEffect(() => {
    let cloneTimeUnit = cloneDeep(timeUnit);
    setActiveBtn(cloneTimeUnit);
  }, []);

  const handleActiveBtn = (e) => {
    setActiveBtn(e.target.id);
    setTimeUnit(e.target.id);
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-center mb-3">
        {/* <div style={{ width: "350px", visibility: "hidden" }}></div> */}
        <div className="d-flex" style={{ width: "280px" }}>
          <IntuitaButton
            text="Second"
            style={{ borderRadius: "4px 0px 0px 4px" }}
            id={"second"}
            className={`${activeBtn === "second" ? "activeBtn" : undefined}`}
            onClick={(e) => {
              handleActiveBtn(e);
              resetComponent();
            }}
          />
          <IntuitaButton
            text="Minute"
            id={"minute"}
            className={`${activeBtn === "minute" ? "activeBtn" : undefined}`}
            onClick={(e) => {
              handleActiveBtn(e);
              resetComponent();
            }}
          />
          <IntuitaButton
            text="Hour"
            style={{ borderRadius: "0px 4px 4px 0px" }}
            id={"hour"}
            className={`${activeBtn === "hour" ? "activeBtn" : undefined}`}
            onClick={(e) => {
              handleActiveBtn(e);
              resetComponent();
            }}
          />
        </div>
        {/* <div className="d-flex justify-content-end" style={{ width: "250px" }}>
          <button
            style={{
              padding: "8px 16px",
              backgroundColor: "var(--secondary-grey)",
              border: "1px solid #222535",
              borderRadius: "4px",
            }}
            className="d-flex align-items-center "
          >
            <GoSettings size="24" color="#F2F2F2" />
            <span
              className="ms-3"
              style={{
                color: "#F2F2F2",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Filters
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default InterventionsHeader;
