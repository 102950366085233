// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /*   base: {
    children : [
      "liveTransactions"
    ],
    id: "base"
  }, */

  liveTransactions: {
    id: "liveTransactions",
    title: "Transactions",
    icon: "zap",
    url: "/live-transactions",
  },

  interventions: {
    id: "interventions",
    title: "Interventions",
    icon: "alert-triangle",
    url: "/interventions",
  },
  userProfile: {
    id: "userProfile",
    title: "Profile",
    icon: "user",
    url: "/profile",
  },

  settings: {
    id: "options",
    title: "Configuration",
    icon: "settings",
    url: "/options",
  },
};
