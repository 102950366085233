import React, {useState, useEffect} from "react";
// import FeatherIcon from "feather-icons-react";
import { Radar } from "react-chartjs-2";
import { radarCommonOptions } from "../shared/Charts/optionsConfig";
import { Chart as ChartJS, registerables } from "chart.js";
import "../../styles/component-style.css";
import {
  profileRiskAveragedTotals,
  profileRiskData,
} from "../../data/mockProfileRiskData";

ChartJS.register(...registerables);

const ProfileRiskChart = ({ playerData }) => {

  const [profileMetricsFinal, setProfileMetricsFinal] = useState([]);

  let profileChartLabels = [
    "Session Time",
    "Daily Session Duration",
    "Heavy Loss Session",
    "Daily loss",
    "Risky Staking",
  ]

  let profileMetricsTempAverage = [];
  let profileMetricsTempCurrent = [];
  let profileMetricsTempFinal = [];

  let profilePercentageData = [];

  for (let i = 0; i < profileRiskAveragedTotals.length; i++) {
    profilePercentageData = [
      ...profilePercentageData,
      ((profileRiskData[i] / profileRiskAveragedTotals[i]) * 100).toFixed(2),
    ];
  }

  const setUpCurrentData = () =>
  {
    profileMetricsTempCurrent = [
      // Session Length
      playerData[0].session.SessionLength,
      // Daily session Length
      playerData[0].metrics.TotalSessionsTime,
      // Heavy Loss Session
      playerData[0].metrics.GGR,
      // Daily Loss
      playerData[0].metrics.LossAmounts + playerData[0].metrics.WinAmounts,
      // Risky staking
      playerData[0].metrics.LastWagerPlaced
    ]
  }

  const setUpAveragedData = () =>
  {
    profileMetricsTempAverage = [
      // Session Length
      playerData[0].metrics.AvgSessionLength, 
      // Daily session Length
      playerData[0].metrics.AvgSessionLength * playerData[0].metrics.AvgSessionsPerDay,
      // Heavy Loss Session
      playerData[0].metrics.AvgGGRPerSession,
      // Daily Loss
      playerData[0].metrics.AvgGGRPerSession * playerData[0].metrics.AvgSessionsPerDay,
      // Risky staking
      playerData[0].metrics.AvgStake ];
  }

  const setUpFinalisedData = () =>
  {
    for (let i = 0; i < profileChartLabels.length; i++) 
    {
      /* console.log(profileMetricsTempCurrent[i]);
      console.log(profileMetricsTempAverage[i]);
      console.log(`${profileChartLabels[i]}`,profileMetricsTempCurrent[i]/profileMetricsTempAverage[i]); */
      if (!isFinite(profileMetricsTempCurrent[i]/profileMetricsTempAverage[i])) 
      {
        profileMetricsTempFinal = [...profileMetricsTempFinal, 0];
      }
      else if (((profileMetricsTempCurrent[i]/profileMetricsTempAverage[i])-1) < 0) 
      {
        profileMetricsTempFinal = [...profileMetricsTempFinal, 0];
      }
      else
      {
        profileMetricsTempFinal = [
          ...profileMetricsTempFinal, 
          ((profileMetricsTempCurrent[i]/profileMetricsTempAverage[i])-1)*100
        ];
      }
    }

    setProfileMetricsFinal(profileMetricsTempFinal);
  }



const setUpMetricData = () =>
{
  setUpAveragedData();
  setUpCurrentData();
  setUpFinalisedData();
}


useEffect(() =>
  {
    if (playerData.length !== 0 && playerData !== undefined) {
      setUpMetricData();
    }
  },[])

  /* useEffect(() =>
  {
    setUpMetricData();
  },[playerData]) */

  const data = {
    labels: profileChartLabels,
    datasets: [
      {
        label: "Player Risk Dispositions",
        data: playerData.length !== 0 ? profileMetricsFinal : profilePercentageData ,
        backgroundColor: "#2d9cdb20",
        borderColor: "#2D9CDB",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ height: "100%", color: "white" }}>
      <div className="component-container" style={{ height: "100%" }}>
        <div style={{ height: "10%" }}>
          <h3 style={{ fontSize: "14px", color: "#a7adcb", fontWeight: "700" }}>
            PROFILE RISK CHART
          </h3>
        </div>
        <div style={{ height: "90%" }}>
          <Radar data={data} options={radarCommonOptions} />
        </div>
      </div>
    </div>
  );
};

export default ProfileRiskChart;
