import React from "react";
import { Route, Routes } from "react-router-dom";

//Layout
import Layout from "./Layout";

//Pages
import Transactions from "./transactions";
import Interventions from "./interventions";
import Profile from "./profile";
import Options from "./options";

const Index = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/live-transactions" element={<Transactions />} />
        <Route path="/interventions" element={<Interventions />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/options" element={<Options />} />
      </Routes>
    </>
  );
};

export default Index;
