import React from "react";
import OptionsKnobs from "../layouts/OptionsKnobs";
import { Container, Row, Col } from "react-bootstrap";

const Options = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} xl={12}>
          <OptionsKnobs />
        </Col>
      </Row>
    </Container>
  );
};

export default Options;
