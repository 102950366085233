const outcomeTypeStyleHard = {
  backgroundColor: "rgba(235, 87, 87, 0.15)",
  fontWeight: "bold",
  color: "rgba(235, 87, 87, 1)",
  padding: "6px 12px",
  marginRight: "1em",
  borderRadius: "24px"
}

const outcomeTypeStyleSoft = {
  backgroundColor: "rgba(242, 201, 76, 0.15)",
  fontWeight: "bold",
  color: "rgba(242, 153, 74, 1)",
  padding: "6px 12px",
  marginRight: "1em",
  borderRadius: "24px"
}


export const tableData = [
  {
    time: "3:42:00 PM",
    id: 14122,
    country: "US",
    intervention: "Time Spent Model",
    outcomeType: "Hard",
    outcome: "Forced Stop",
    lifetimeValue: "$2,583",
    outcomeStyle: outcomeTypeStyleHard
  },
  {
    time: "3:42:00 PM",
    id: 124134,
    country: "US",
    intervention: "Unusual Session",
    outcomeType: "Soft",
    outcome: "Suggested Break",
    lifetimeValue: "$405",
    outcomeStyle: outcomeTypeStyleSoft
  },
  {
    time: "3:42:00 PM",
    id: 23435,
    country: "US",
    intervention: "Unusual Session",
    outcomeType: "Soft",
    outcome: "Suggested Break",
    lifetimeValue: "$1,200",
    outcomeStyle: outcomeTypeStyleSoft
  },
  {
    time: "3:42:00 PM",
    id: 23544,
    country: "US",
    intervention: "Self Hard Model RED",
    outcomeType: "Hard",
    outcome: "Deposit Limit",
    lifetimeValue: "$29,363",
    outcomeStyle: outcomeTypeStyleHard
  },
  {
    time: "3:42:00 PM",
    id: 23435,
    country: "US",
    intervention: "Unusual Session",
    outcomeType: "Soft",
    outcome: "Suggested Break",
    lifetimeValue: "$2,583",
    outcomeStyle: outcomeTypeStyleSoft
  }
]

export const profileTableData = [
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "5",
    balance: "120",
    risk: "58",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "105",
    balance: "105",
    risk: "42",
    /* riskStyle: outcomeTypeStyleSoft */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Deposit",
    stake: "100",
    balance: "293",
    risk: "38",
    /* riskStyle: outcomeTypeStyleSoft */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "15",
    balance: "95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Loss",
    stake: "$15",
    balance: "$95",
    risk: "69",
    /*     riskStyle: outcomeTypeStyleHard */
  },
  {
    date: "11-17-22",
    time: "3:42:00 PM",
    event: "Spin",
    country: "US",
    game: "Slots 3000",
    outcome: "Win",
    stake: "10",
    balance: "110",
    risk: "57",
    /*     riskStyle: outcomeTypeStyleHard */
  },
]