import React from "react";
import ProfileRiskTrend from "./ProfileRiskTrend";
import ProfileRiskChart from "./ProfileRiskChart";

const ProfileMain = ({ playerData }) => {
  return (
    <div>
      <div className='my-5 d-flex justify-content-between' style={{height: "60vh"}}>
        <div style={{width: "49%"}}>
          <ProfileRiskChart playerData={playerData}/>
        </div>
        <div style={{ width: "49%" }}>
          <ProfileRiskTrend playerData={playerData} />
        </div>
      </div>
    </div>
  );
};

export default ProfileMain;
