import React, { useState, useEffect } from "react";
import InterventionsMain from "./InterventionsMain";
import LoadingSpin from "react-loading-spin";
import { useQuery as apolloQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { format } from "date-fns";
import {
  GET_INCREMENTALS,
  NEW_INCREMENTAL,
  NEW_INCREMENTAL_MINUTE,
  NEW_INCREMENTAL_HOUR,
} from "../../graphql/queries";

const InterventionsMainCont = ({ timeUnit }) => {
  const [todaysDate, setTodaysDate] = useState("");

  const {
    loading: mainLoad,
    data: mainData,
    error: mainError,
    subscribeToMore: transactionInterventionSub,
  } = apolloQuery(GET_INCREMENTALS, {
    variables: {
      startTime: todaysDate,
      endTime: "2099-12-31T23:59:59.999999+00:00",
      unit: timeUnit,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setUpInterventionChartDate();
  }, []);

  useEffect(() => {
    setUpInterventionChartDate();
  }, [timeUnit]);

  const setUpInterventionChartDate = () => {
    let today = new Date();
    let roundedToday = "";
    let roundedTodayInMiliSec = null;
    let lastSixtyValuesMilisecondsInterval = null;

    switch (timeUnit) {
      case "second": {
        roundedToday = new Date(`${
          today.getMonth() + 1
        }-${today.getDate()}-${today.getFullYear()} 
        ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`);
        roundedTodayInMiliSec = roundedToday.getTime();
        lastSixtyValuesMilisecondsInterval = 60 * 1000;
        break;
      }
      case "minute": {
        roundedToday = new Date(`${
          today.getMonth() + 1
        }-${today.getDate()}-${today.getFullYear()} 
        ${today.getHours()}:${today.getMinutes()}:00`);
        roundedTodayInMiliSec = roundedToday.getTime();
        lastSixtyValuesMilisecondsInterval = 60 * 60 * 1000;
        break;
      }
      case "hour": {
        roundedToday = new Date(`${
          today.getMonth() + 1
        }-${today.getDate()}-${today.getFullYear()} 
        ${today.getHours()}:00:00`);
        roundedTodayInMiliSec = roundedToday.getTime();
        lastSixtyValuesMilisecondsInterval = 60 * 60 * 60 * 1000;
        break;
      }
      default:
        break;
    }
    let startingDate = new Date(
      roundedTodayInMiliSec - lastSixtyValuesMilisecondsInterval
    );
    let roundedDatePart = format(startingDate, "yyyy-LL-dd");
    let roundedHourPart = format(startingDate, "HH:mm:ss.ssssssxxx");
    let completeString = `${roundedDatePart}T${roundedHourPart}`;

    setTodaysDate(completeString);
  };

  return (
    <>
      {mainLoad || !mainData ? (
        <div
          className="d-flex flex-column mt-5 align-items-center justify-content-center"
          style={{ height: "45vh" }}
        >
          <h2 className="mb-3" style={{ color: "var(--intuita-main)" }}>
            Fetching data...
          </h2>
          <LoadingSpin size="150px" primaryColor="var(--intuita-main)" />
        </div>
      ) : (
        <InterventionsMain
          data={mainData.incrementals}
          timeUnit={timeUnit}
          subscription={() =>
            transactionInterventionSub({
              document:
                timeUnit === "second"
                  ? NEW_INCREMENTAL
                  : timeUnit === "minute"
                  ? NEW_INCREMENTAL_MINUTE
                  : NEW_INCREMENTAL_HOUR,
              updateQuery: (prev, { subscriptionData }) => {
                let recordExists = false;
                let indexOfExistingRecord = null;
                let prevClone = cloneDeep(prev);
                let newIncrement = null;
                if (subscriptionData.data.newIncremental) {
                  newIncrement = subscriptionData.data.newIncremental;
                } else if (subscriptionData.data.newIncrementalMinute) {
                  newIncrement = subscriptionData.data.newIncrementalMinute;
                } else {
                  newIncrement = subscriptionData.data.newIncrementalHour;
                }

                for (let i = 0; i < prevClone.incrementals.length; i++) {
                  if (
                    prevClone.incrementals[i].state === newIncrement.state &&
                    prevClone.incrementals[i].time === newIncrement.time
                  ) {
                    recordExists = true;
                    indexOfExistingRecord = i;
                  }
                }
                if (recordExists) {
                  prevClone.incrementals[indexOfExistingRecord] = newIncrement;
                  return prevClone;
                } else {
                  return Object.assign({}, prevClone, {
                    incrementals: [...prevClone.incrementals, newIncrement],
                  });
                }
              },
            })
          }
        />
      )}
    </>
  );
};

export default InterventionsMainCont;
