export const commonOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
      alignment: "center",
      position: "right",
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
        autoSkip: true,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#222535",
      },
    },
    y: {
      min: 0,
      ticks: {
        autoSkip: true,
        stepSize: 50000000,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: true,
        drawBorder: false,
        color: "#222535",
      },
    },
  },
};

export const cardAreaChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
      alignment: "center",
      position: "right",
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
        autoSkip: true,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#222535",
      },
    },
    y: {
      ticks: {
        display: false,
        autoSkip: true,
        stepSize: 50000000,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#222535",
      },
    },
  },
};

export const cardAreaChartOptionsGGR = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
      alignment: "center",
      position: "right",
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
        autoSkip: true,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#222535",
      },
    },
    y: {
      grace: "5%",
      ticks: {
        display: false,
        autoSkip: true,
        stepSize: 50000000,
        maxRotation: 0,
        padding: 5,
        minRotation: 0,
        color: "#4B516D",
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#222535",
      },
    },
  },
};

export const radarCommonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
      alignment: "center",
      position: "right",
    },
  },
  scales: {
    x: {
      ticks: {
        display: false,
        autoSkip: true,
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#4B516D",
      },
    },
    y: {
      ticks: {
        display: false,
        autoSkip: true,
      },
      grid: {
        display: false,
        drawBorder: false,
        color: "#4B516D",
      },
    },
    r: {
      min: 0,
      max: 300,
      ticks: {
        beginAtZero: true,
        display: true,
        backdropColor: "rgba(0,0,0,0)",
        callback: (value) => {
          return value+"%";
        },
      },
      grid: {
        color: (context) =>
        {
          if (context.tick.value === 50) 
          {
            return "rgb(255,255,255)";
          }
          else
          {
            return "#222535"
          }
        }
      }
    }
  },
};
