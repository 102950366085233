import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { commonOptions } from "../shared/Charts/optionsConfig";
import { Chart as ChartJS, registerables } from "chart.js";
import nFormatter from "../../utils/numberFormatter";
import { format } from "date-fns";
import "../../styles/component-style.css";

ChartJS.register(...registerables);

const InterventionsChart = ({ data, subscription, timeUnit }) => {
  const [timeLabels, setTimeLabels] = useState([]);
  const [interventionsSet, setInterventionsSet] = useState([]);
  const [totalInterventions, setTotalInterventions] = useState(0);

  const backgroundColorArray = ["#fd7e14", "#2D9CDB"];
  const borderColorArray = ["#fd7e1480", "#2D9CDB80"];

  let timeLabelsTemp = [];
  let interventionsSetTemp = [];
  let totalInterventionsTemp = 0;

  const setUpGraphValues = (initialData) => {
    initialData.forEach((element) => {
      let newTimeString = new Date(element.time);
      let formattedTimeString = format(newTimeString, "yyyy-LL-dd H:mm:ss");
      if (!timeLabelsTemp.includes(formattedTimeString)) {
        timeLabelsTemp = [...timeLabelsTemp, formattedTimeString];
        interventionsSetTemp = [...interventionsSetTemp, element.interventions];
      } else {
        let timeStringIndex = timeLabelsTemp.indexOf(formattedTimeString);
        interventionsSetTemp[timeStringIndex] += element.interventions;
      }
      totalInterventionsTemp += element.interventions;
    });

    let slicedTimeLabels = timeLabelsTemp.slice(-60);
    let slicedInterventions = interventionsSetTemp.slice(-60);

    setTimeLabels(slicedTimeLabels);
    setInterventionsSet(slicedInterventions);
    setTotalInterventions(totalInterventionsTemp);
  };

  useEffect(() => {
    subscription();
  }, []);

  useEffect(() => {
    setUpGraphValues(data);
  }, [data]);

  const latestItemColor = (data, colorArray) => {
    if (data.index === timeLabels.length - 1) {
      return colorArray[0];
    } else {
      return colorArray[1];
    }
  };

  return (
    <div style={{ height: "100%", color: "white" }}>
      <div className="component-container" style={{ height: "100%" }}>
        <div style={{ height: "20%" }}>
          <div className="d-flex justify-content-between">
            <h3
              style={{ fontSize: "14px", color: "#a7adcb", fontWeight: "700" }}
            >
              INTERVENTIONS
            </h3>
            <p style={{ margin: "0", color: "white", fontSize: "12px" }}>
              LAST 60{" "}
              {timeUnit === "second"
                ? "SECONDS"
                : timeUnit === "minute"
                ? "MINUTES"
                : "HOURS"}
            </p>
          </div>
          <p style={{ fontSize: "40px", fontWeight: "700" }}>
            {nFormatter(totalInterventions, 1)}
          </p>
        </div>
        <div style={{ height: "80%" }}>
          <Bar
            data={{
              labels: timeLabels,
              datasets: [
                {
                  label: "Interventions",
                  data: interventionsSet,
                  fill: true,
                  backgroundColor: (data) =>
                    latestItemColor(data, backgroundColorArray),
                  borderColor: (data) =>
                    latestItemColor(data, borderColorArray),
                },
              ],
            }}
            options={commonOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default InterventionsChart;
