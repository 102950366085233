import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import navigation from "../../../data/navigation";
import IntuitaSidebarLogo from "../../../assets/img/Intuita logo_white_transparent background.png";

import "./sidenav.css";

const Sidenav = () => {
  const router = useLocation();
  const [coloredItemId, setColoredItemId] = useState("");

  useEffect(() => {
    Object.keys(navigation).forEach((item) => {
      if (navigation[item].url === router.pathname) {
        setColoredItemId(navigation[item].id);
      }
    });
  }, [router.pathname]);

  const handleColoredItemChange = (id) => {
    if (coloredItemId !== id) {
      setColoredItemId(id);
    }
  };

  const getItems = (ids) => {
    return ids.map((id) => {
      const item = navigation[id];
      return (
        <Nav.Item className="w-100" key={id}>
          <span
            className="py-0 pe-0 w-100"
            style={{ height: "2.5em", textDecoration: "none" }}
            active={(router.pathname === item.url).toString()}
            onClick={() => {
              handleColoredItemChange(id);
            }}
          >
            <NavLink
              className="d-inline-flex align-items-center w-100 h-100"
              style={{
                backgroundColor:
                  coloredItemId === id
                    ? "rgba(45, 156, 219, 0.25)"
                    : "rgba(45, 156, 219, 0)",
                border:
                  coloredItemId === id
                    ? "1px solid rgba(45, 156, 219, 1)"
                    : "none",
                borderRadius: coloredItemId === id ? "4px" : "0px",
                textDecoration: "none",
              }}
              /*               style={{color:coloredItemId === id ? "rgba(242,242,242,1)": "rgba(242,242,242,0.5)",}} */
              to={item.url}
            >
              <FeatherIcon
                icon={item.icon}
                style={{
                  color:
                    coloredItemId === id ? "rgba(45, 156, 219, 1)" : "white",
                  padding: "10px",
                }}
                size="48"
              />
              <span
                className="navbar-link-title"
                style={{
                  color:
                    coloredItemId === id ? "rgba(45, 156, 219, 1)" : "white",
                }}
              >
                {item.title}
              </span>
            </NavLink>
          </span>
        </Nav.Item>
      );
    });
  };

  const brand = (
    <NavLink
      to="/live-transactions"
      className=" mb-3 text-decoration-none"
      style={{ height: "6em" }}
    >
      <Navbar.Brand className="d-flex flex-column">
        <div className="d-flex align-items-center m-0 w-100">
          <h1 className="navbar-site-title">PLAYER PROTECTION</h1>
        </div>
      </Navbar.Brand>
    </NavLink>
  );

  const footerLogo = (
    <div className="d-flex justify-content-center align-items-center w-100">
      <img
        style={{ width: "105px", height: "30px" }}
        src={IntuitaSidebarLogo}
        alt="Intuita Logo"
      />
    </div>
  );

  return (
    <>
      <Navbar
        className="navbar-vertical fixed-start d-flex justify-content-between"
        style={{ backgroundColor: "#191a25" }}
        collapseOnSelect={true}
      >
        <div>
          <div className="mb-5">{brand}</div>
          {getItems(Object.keys(navigation))}
        </div>
        {footerLogo}
      </Navbar>
    </>
  );
};

export default Sidenav;
