import Sidenav from "./components/shared/Sidenav/Sidenav";
import Pages from "./pages";

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

function App() {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <div>
          <Sidenav />
          <div
            style={{
              marginLeft: "15em",
              backgroundColor: "var(--secondary-grey)",
              minHeight: "100vh",
            }}
          >
            <Pages />
          </div>
        </div>
      )}
    </Authenticator>
  );
}

export default App;
