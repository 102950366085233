import React, { useRef } from "react";
import { useEffect } from "react";
import InterventionsChart from "./InterventionsChart";

const InterventionsMain = ({ data, subscription, timeUnit }) => {
  const iFrameRef = useRef(null);

  const iFrameParamsDefinition = () => {
    let urlString = "https://map.sparrowdragon.com";
    let timeString = urlString + "/?timeUnit=" + timeUnit;
    iFrameRef.current.src = timeString;
  };

  useEffect(() => {
    iFrameParamsDefinition();
  }, []);

  return (
    <div>
      <div
        className="mt-3 mb-4 d-flex justify-content-between gap-3"
        style={{ height: "60vh" }}
      >
        <div className="w-50">
          <InterventionsChart
            data={data}
            subscription={subscription}
            timeUnit={timeUnit}
          />
        </div>
        <div className="w-50 h-100">
          <div className="component-container h-100">
            <h3
              style={{
                fontSize: "14px",
                color: "#a7adcb",
                fontWeight: "700",
                height: "5%",
              }}
            >
              HEATMAP
            </h3>
            <iframe
              width="100%"
              height="95%"
              id="mapFrame"
              ref={iFrameRef}
              title="Mapbox New Jersey"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            {/* {iFrameParamsDefinition()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionsMain;
