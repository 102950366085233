import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import "../../styles/component-style.css";
import { cloneDeep } from "lodash";
import InterventionStatusIcon from "../shared/InterventionStatusIcon";

const InterventionsTable = ({ data, subscription }) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [sortedColumnsNames, setSortedColumnNames] = useState([]);
  let columnNames = [];

  useEffect(() => {
    if (data.length !== 0 || data !== undefined) {
      let cloneData = cloneDeep(data);
      let slicedData = cloneData.slice(0, 200);
      setTableData(slicedData);
      subscription();
    }
  }, []);

  useEffect(() => {
    if (data.length !== 0 || data !== undefined) {
      columnNames = Object.keys(data[0] || []);
      if (columnNames.length === 0) {
        setSortedColumnNames([]);
      } else {
        let filteredColumns = columnNames.filter((item) => {
          if (
            item !== "session_start" &&
            item !== "country_code" &&
            item !== "state_name" &&
            item !== "county_fips" &&
            item !== "__typename"
          ) {
            return item;
          }
          return null;
        });
        setSortedColumnNames(filteredColumns);
      }
    }
  }, []);

  useEffect(() => {
    let cloneData = cloneDeep(data);
    let slicedData = cloneData.slice(0, 200);
    setTableData(slicedData);
  }, [data]);

  const formatNumber = (value) => {
    let result = 0;
    if (value > 1000) {
      result = value / 1000;
    }
    result = parseFloat(result.toFixed(2));
    return result;
  };

  return (
    <div>
      <div
        className="component-container mt-5"
        style={
          tableData?.length < 5 ? { maxHeight: "45vh" } : { height: "45vh" }
        }
      >
        <h3 style={{ fontSize: "14px", fontWeight: "700", color: "#a7adcb" }}>
          STATISTICS
        </h3>
        <div
          className="w-100 overflow-auto overflow-style"
          style={{ height: "90%" }}
        >
          {data.length === 0 || data === undefined ? (
            <p style={{ textAlign: "center", color: "white" }}>
              NO STATISTICS AT THE MOMENT
            </p>
          ) : (
            <table className="w-100 m-0 text-white">
              <thead>
                <tr
                  style={{
                    height: "3.5em",
                    borderBottom: "1px solid #222535",
                    fontSize: "14px",
                  }}
                >
                  {sortedColumnsNames.length === 0
                    ? null
                    : sortedColumnsNames.map((item, index) => {
                        let newColumnName = item
                          .replace("_", " ")
                          .toUpperCase();
                        if (item === "state_code") {
                          let stateName = item
                            .slice(0, item.indexOf("_"))
                            .toUpperCase();
                          return (
                            <td
                              style={{
                                fontWeight: "700",
                                wordWrap: "break-word",
                              }}
                            >
                              {stateName}
                            </td>
                          );
                        } else if (item === "county_name") {
                          let countyName = item
                            .slice(0, item.indexOf("_"))
                            .toUpperCase();
                          return (
                            <td
                              style={{
                                fontWeight: "700",
                                wordWrap: "break-word",
                              }}
                            >
                              {countyName}
                            </td>
                          );
                        } else if (item === "interv_time") {
                          return (
                            <>
                              <td
                                style={{
                                  fontWeight: "700",
                                  wordWrap: "break-word",
                                }}
                              >
                                INTERV DATE
                              </td>
                              <td
                                style={{
                                  fontWeight: "700",
                                  wordWrap: "break-word",
                                }}
                              >
                                {newColumnName}
                              </td>
                            </>
                          );
                        } else if (index === sortedColumnsNames.length - 1) {
                          return (
                            <td style={{ fontWeight: "700" }} colSpan={2}>
                              {newColumnName}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              style={{
                                fontWeight: "700",
                                wordWrap: "break-word",
                              }}
                            >
                              {newColumnName}
                            </td>
                          );
                        }
                      })}
                </tr>
              </thead>
              <tbody>
                {tableData.length === 0
                  ? null
                  : tableData.map((item, index) => {
                      let defaultTime = new Date(item.interv_time);
                      let formattedTime = format(defaultTime, "pp");
                      let formattedDate = format(defaultTime, "LL-dd-yy");
                      return (
                        <tr
                        key={index}
                          style={{
                            height: "2.5em",
                            borderBottom: "1px solid #222535",
                            fontSize: "14px",
                          }}
                        >
                          {sortedColumnsNames.map((nameItem) => {
                            if (item[nameItem] && nameItem === "interv_time") {
                              return (
                                <>
                                  <td>{formattedDate}</td>
                                  <td>{formattedTime}</td>
                                </>
                              );
                            } else if (
                              item[nameItem] &&
                              nameItem === "interv_type"
                            ) {
                              return (
                                <td>
                                  <InterventionStatusIcon
                                    value={item[nameItem]}
                                  />
                                </td>
                              );
                            } else if (item[nameItem] && nameItem === "clv") {
                              return <td>${formatNumber(item[nameItem])}K</td>;
                            } else if (
                              item[nameItem] &&
                              nameItem === "session_ggr"
                            ) {
                              return (
                                <td>
                                  {item[nameItem] < 0 ? "-$" : "$"}
                                  {formatNumber(Math.abs(item[nameItem]))}K
                                </td>
                              );
                            } else {
                              return <td>{item[nameItem]}</td>;
                            }
                          })}
                          <td>
                            <BsThreeDots
                              className="react-icon"
                              onClick={() => {
                                navigate("/profile", {
                                  state: {
                                    playerId: item.player_id,
                                    playerIntID: item.player_id,
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterventionsTable;
