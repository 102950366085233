import React, { useState } from "react";
import IntuitaButton from "../components/shared/Button/IntuitaButton";
import { IoIosInformationCircleOutline as InfoIcon } from "react-icons/io";
import ExtSessionPlayingTime from "../components/options-components/ExtSessionPlayingTime";
import InSessionHeavyLosses from "../components/options-components/InSessionHeavyLosses";
import DailyExtPlayingTime from "../components/options-components/DailyExtPlayingTime";
import TotalLifetimeInter from "../components/options-components/TotalLifetimeInter";
import DailyHeavyLosses from "../components/options-components/DailyHeavyLosses";
import RiskyStaking from "../components/options-components/RiskyStaking";
import ConfirmationModal from "../components/shared/ConfirmationModal/ConfirmationModal";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "../styles/option-input-style.css";

const initialState = {
  extPlayTimeMinSoft: "60",
  extPlayTimeMinHard: "120",
  extPlayTimeAvgSessSoft: "2.0",
  extPlayTimeAvgSessHard: "3.0",
  extPlayTimeMaxSessSoft: "120",
  extPlayTimeMaxSessHard: "300",
  inSessHeavyLossMinSoft: "100",
  inSessHeavyLossMinHard: "250",
  inSessHeavyLossAvgSessSoft: "2.0",
  inSessHeavyLossAvgSessHard: "3.0",
  inSessHeavyLossMaxSoft: "5000",
  inSessHeavyLossMaxHard: "7500",
  dailyExtPlayMaxSoft: "240",
  dailyExtPlayMaxHard: "420",
  dailyHeavyLossAvgSoft: "2.0",
  dailyHeavyLossAvgHard: "3.0",
  riskyStakingMinSoft: "10",
  riskyStakingAvgSoft: "2.0",
  totalLifeInterHard: "3.0",
};

const OptionsKnobs = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [cancelChanges, setCancelChanges] = useState(false);

  const [extPlayTimeMinSoft, setExtPlayTimeMinSoft] = useState(60);
  const [extPlayTimeMinHard, setExtPlayTimeMinHard] = useState(120);
  const [extPlayTimeAvgSessSoft, setExtPlayTimeAvgSessSoft] = useState(2.0);
  const [extPlayTimeAvgSessHard, setExtPlayTimeAvgSessHard] = useState(3.0);
  const [extPlayTimeMaxSessSoft, setExtPlayTimeMaxSessSoft] = useState(120);
  const [extPlayTimeMaxSessHard, setExtPlayTimeMaxSessHard] = useState(300);

  const [inSessHeavyLossMinSoft, setInSessHeavyLossMinSoft] = useState(100);
  const [inSessHeavyLossMinHard, setInSessHeavyLossMinHard] = useState(250);
  const [inSessHeavyLossAvgSessSoft, setInSessHeavyLossAvgSessSoft] =
    useState(2.0);
  const [inSessHeavyLossAvgSessHard, setInSessHeavyLossAvgSessHard] =
    useState(3.0);
  const [inSessHeavyLossMaxSoft, setInSessHeavyLossMaxSoft] = useState(5000);
  const [inSessHeavyLossMaxHard, setInSessHeavyLossMaxHard] = useState(7500);

  const [dailyExtPlayMaxSoft, setDailyExtPlayMaxSoft] = useState(240);
  const [dailyExtPlayMaxHard, setDailyExtPlayMaxHard] = useState(420);

  const [dailyHeavyLossAvgSoft, setDailyHeavyLossAvgSoft] = useState(2.0);
  const [dailyHeavyLossAvgHard, setDailyHeavyLossAvgHard] = useState(3.0);

  const [riskyStakingMinSoft, setRiskyStakingMinSoft] = useState(10);
  const [riskyStakingAvgSoft, setRiskyStakingAvgSoft] = useState(2.0);

  const [totalLifeInterHard, setTotalLifeInterHard] = useState(3.0);

  const [configDetails, setConfigDetails] = useState({
    extPlayTimeMinSoft: "60",
    extPlayTimeMinHard: "120",
    extPlayTimeAvgSessSoft: "2.0",
    extPlayTimeAvgSessHard: "3.0",
    extPlayTimeMaxSessSoft: "120",
    extPlayTimeMaxSessHard: "300",
    inSessHeavyLossMinSoft: "100",
    inSessHeavyLossMinHard: "250",
    inSessHeavyLossAvgSessSoft: "2.0",
    inSessHeavyLossAvgSessHard: "3.0",
    inSessHeavyLossMaxSoft: "5000",
    inSessHeavyLossMaxHard: "7500",
    dailyExtPlayMaxSoft: "240",
    dailyExtPlayMaxHard: "420",
    dailyHeavyLossAvgSoft: "2.0",
    dailyHeavyLossAvgHard: "3.0",
    riskyStakingMinSoft: "10",
    riskyStakingAvgSoft: "2.0",
    totalLifeInterHard: "3.0",
  });

  const [titleTextTest, setTitleTextTest] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfigDetails((prev) => {
      return { ...prev, [name]: value };
    });
  };

  // save the configuration details to the local storage
  const saveConfiguration = () => {
    if (
      Number(configDetails.extPlayTimeMinSoft) <
      Number(configDetails.extPlayTimeMinHard)
    ) {
      alert("It's all good!");
      localStorage.setItem("configDetails", JSON.stringify(configDetails));
    } else {
      alert("Minimum soft limit should be less than the minimum hard limit");
    }
  };

  return (
    <main className="px-5 py-3">
      {showConfirmationModal && (
        <ConfirmationModal
          titleText={titleTextTest}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      {/* REAL HEADER start */}
      <div className="component-container w-75 mb-4">
        <div className="d-flex align-items- justify-content-end gap-3">
          <IntuitaButton
            text="Cancel"
            className="btn_submits-style"
            style={{
              color: "#EB5757",
            }}
            onClick={() => {
              setTitleTextTest("Cancel Changes");
              setShowConfirmationModal(true);
              // saveConfiguration();
            }}
          />
          <IntuitaButton
            text="Save"
            className="btn_submits-style"
            onClick={() => {
              setTitleTextTest("Save Changes");
              setShowConfirmationModal(true);
              // saveConfiguration();
            }}
          />
        </div>
      </div>
      {/* REAL HEADER end */}

      <div className="w-75 text-white">
        {/* INTERVENTION TYPES start */}
        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex align-items-center w-50">
            <h5
              className="w-50 mt-3 text-center fw-bold"
              style={{ color: "#A7ADCB", fontSize: "14px" }}
            >
              Soft Intervention{" "}
              <Tippy
                theme="light"
                content="Soft Interventions interact with a player in real time and prompt them to take action
                | After receiving a Soft Interaction players can continue to play"
                arrow={false}
              >
                <span>
                  <InfoIcon
                    size="14"
                    color="var(--secondary-blue)"
                    className="position-absolute translate-middle-y"
                  />
                </span>
              </Tippy>
            </h5>

            <h5
              className="w-50 mt-3 text-center fw-bold"
              style={{ color: "#A7ADCB", fontSize: "14px" }}
            >
              Hard Intervention{" "}
              <Tippy
                theme="light"
                content="Hard Interventions stop a players session in real time | After receiving a Hard Intervention players can’t continue to play"
                arrow={false}
              >
                <span>
                  <InfoIcon
                    size="14"
                    color="var(--secondary-blue)"
                    className="position-absolute translate-middle-y"
                  />
                </span>
              </Tippy>
            </h5>
          </div>
        </div>
        {/* INTERVENTINO TYPES end */}

        <ExtSessionPlayingTime
          configDetails={configDetails}
          handleChange={handleChange}
        />

        <InSessionHeavyLosses
          configDetails={configDetails}
          handleChange={handleChange}
        />

        <DailyExtPlayingTime
          handleChange={handleChange}
          configDetails={configDetails}
        />

        <DailyHeavyLosses
          handleChange={handleChange}
          configDetails={configDetails}
        />

        <RiskyStaking
          handleChange={handleChange}
          configDetails={configDetails}
        />

        {/* TOTAL LIFETIME INTERVENTIONS   */}
        <TotalLifetimeInter
          configDetails={configDetails}
          handleChange={handleChange}
        />
      </div>
    </main>
  );
};

export default OptionsKnobs;
