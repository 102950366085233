import React from 'react'

const INTERVENTION_STATUS_COLOR_SERIES = ["rgba(163, 45, 45, 1)", "rgba(215, 134, 54, 1)"];

const InterventionStatusIcon = (props) => {

  let statusColor = "";
  switch (props.value) 
  {
    case "HARD":
    {
      statusColor = INTERVENTION_STATUS_COLOR_SERIES[0];
      break;
    }
    case "SOFT":
    {
      statusColor = INTERVENTION_STATUS_COLOR_SERIES[1]
      break;
    }
    default:
      break;
  }

  return (
    <div className="d-inline-flex ms-3"
      style={{
        width: "20px",
        height: "20px",
        backgroundColor: `${statusColor}`,
        borderRadius: "10px",
        margin: "0.5em auto",
      }}
    />
  )
}

export default InterventionStatusIcon