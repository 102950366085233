import React, { useState } from 'react'
import InterventionsLayout from '../layouts/InterventionsLayout';
import { Container, Row, Col } from "react-bootstrap";

const Interventions = () => {

  return (
    <Container fluid>
      <Row>
        <Col xs={12} xl={12}>
          <InterventionsLayout/>
        </Col>
      </Row>
    </Container>
  )
}

export default Interventions