import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { IoIosInformationCircleOutline as InfoIcon } from "react-icons/io";

const RiskyStaking = ({
  handleChange,
  configDetails,
  riskyStakingMinSoft,
  setRiskyStakingMinSoft,
  riskyStakingAvgSoft,
  setRiskyStakingAvgSoft,
}) => {
  return (
    <div className="mt-3 w-100 ps-3 card-style">
      {/* Header */}
      <div className="d-flex justify-content-between w-100">
        <h4 className="w-50 fw-bold mb-0">
          Risky Staking{" "}
          <Tippy
            theme="light"
            content="Protect players from unusually high stakes |

            MIn (amount) - set a players minimum stake amount before triggering an intervention |
            
            Avg - Set how many times higher a players stake amount can be above their average before triggering an intervention |"
            arrow={false}
          >
            <span>
              <InfoIcon
                size="14"
                color="var(--secondary-blue)"
                className=" position-absolute translate-middle-y"
              />
            </span>
          </Tippy>
        </h4>
      </div>

      {/* Minimum */}
      <div className="d-flex justify-content-between w-100 align-items-center my-1">
        <p style={{ marginBottom: "0" }} className="w-50">
          Minimum (amount)
        </p>
        <div className="d-flex align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="riskyStakingMinSoft"
              value={configDetails.riskyStakingMinSoft}
              // defaultValue={riskyStakingMinSoft}
              onChange={handleChange}
              className=" inpt-option form-control "
            />
          </div>
        </div>
      </div>
      {/* Average Session Time Multiplier */}
      <div className="d-flex justify-content-between w-100 align-items-center mb-3">
        <p style={{ marginBottom: "0" }} className="w-50">
          Average Staking Multiplier
        </p>
        <div className="d-flex align-items-center w-50 align-items-center">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="riskyStakingAvgSoft"
              value={configDetails.riskyStakingAvgSoft}
              // defaultValue={riskyStakingAvgSoft}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskyStaking;
