import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { IoIosInformationCircleOutline as InfoIcon } from "react-icons/io";

const TotalLifetimeInter = ({ configDetails, handleChange }) => {
  return (
    <div className="mt-3 w-100 ps-3 card-style">
      {/* Header */}
      <div className="d-flex">
        <h4 className="w-50 fw-bold mb-0">
          Total Lifetime Interventions{" "}
          <Tippy
            theme="light"
            content="Protect players who repeatedly demonstrate significant harmful behaviours |

            Max - set number of interventinos a player can trigger before account suspension"
            arrow={false}
          >
            <span>
              <InfoIcon
                size="14"
                color="var(--secondary-blue)"
                className=" position-absolute translate-middle-y"
              />
            </span>
          </Tippy>
        </h4>
      </div>

      {/* Maximum */}
      <div className="d-flex justify-content-between w-100 align-items-center mb-3">
        <p className="w-50 mb-0">Maximum</p>
        <div className="d-flex justify-content-end align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="totalLifeInterHard"
              value={configDetails.totalLifeInterHard}
              // defaultValue={totalLifeInterHard}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalLifetimeInter;
