import { InputGroup, Form } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

const InputBar = ({ ...props }) => {
  return (
    <div className={props.className}>
      <InputGroup className="input-group-merge input-group-reverse">
        <InputGroup.Text
          className="border-0"
          style={{ backgroundColor: "var(--primary-grey)" }}
        >
          {props.icon ? (
            <FeatherIcon
              icon={props.icon}
              color={props.color || "#2D9CDB"}
              size="1.25em"
            />
          ) : null}
        </InputGroup.Text>
        <Form.Control
          className="border-0"
          style={{ backgroundColor: "var(--primary-grey)", color: "#6c757d" }}
          type={props.type || "search"}
          placeholder={props.placeholder || "Search"}
          value={props.value}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          onSubmit={props.onSubmit}
          format={props.format}
          mask={props.mask}
          as={props.as}
        />
      </InputGroup>
    </div>
  );
};

export default InputBar;
