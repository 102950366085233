import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { IoIosInformationCircleOutline as InfoIcon } from "react-icons/io";

const DailyExtPlayingTime = ({
  handleChange,
  configDetails,
  dailyExtPlayMaxSoft,
  setDailyExtPlayMaxSoft,
  dailyExtPlayMaxHard,
  setDailyExtPlayMaxHard,
}) => {
  return (
    <div className="mt-3 ps-3 w-100 card-style">
      {/* Header */}
      <div className="d-flex justify-content-between w-100 ">
        <h4 className="w-50 fw-bold">
          Daily Extended Playing TIme{" "}
          <Tippy
            theme="light"
            content="Protect players from binging during a full day | 

            Max -  set the maximum amount of time a player can play before triggering an intervention."
            arrow={false}
          >
            <span>
              <InfoIcon
                size="14"
                color="var(--secondary-blue)"
                className=" position-absolute translate-middle-y"
              />
            </span>
          </Tippy>
        </h4>
      </div>

      {/* Maximum */}
      <div className="d-flex justify-content-between w-100 align-items-center mb-3">
        <p style={{ marginBottom: "0" }} className="w-50">
          Maximum (minutes)
        </p>
        <div className="d-flex align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="dailyExtPlayMaxSoft"
              value={configDetails.dailyExtPlayMaxSoft}
              // defaultValue={dailyExtPlayMaxSoft}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="dailyExtPlayMaxHard"
              value={configDetails.dailyExtPlayMaxHard}
              // defaultValue={dailyExtPlayMaxHard}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyExtPlayingTime;
