import { gql } from "@apollo/client";

export const GET_INCREMENTALS = gql`
query GetIncrementals ($startTime: String!, $endTime: String!, $unit: String!) {
  incrementals(start_time: $startTime, end_time: $endTime, unit: $unit) {
    state
    time
    count
    txn_total
    interventions
    ggr_total
  }
}`

export const NEW_INCREMENTAL = gql `
subscription NewIncremental {
  newIncremental {
    state
    time
    count
    txn_total
    interventions
    ggr_total
  }
}`

export const NEW_INCREMENTAL_MINUTE = gql `
subscription NewIncrementalMinute {
  newIncrementalMinute {
    state
    time
    count
    txn_total
    interventions
    ggr_total
  }
}`

export const NEW_INCREMENTAL_HOUR = gql `
subscription NewIncrementalHour {
  newIncrementalHour {
    state
    time
    count
    txn_total
    interventions
    ggr_total
  }
}`






/* export const GET_TRANSACTIONS_AGG_BY_MINUTE = gql `
query GetTransactionaAggByMinute {
  aggregatesByMinute {
    state
    time
    count
    txn_total
    interventions_total
    ggr_total
  }
}`;

export const GET_TRANSACTIONS_AGG_BY_MINUTE_FILTER = gql `
query GetTransactionsAggByMinuteFilter($startTime: String!, $endTime: String!) {
  aggregatesByMinuteFilter(start_time: $startTime, end_time: $endTime) {
    state
    time
    count
    txn_total
    interventions_total
    ggr_total
  }
}`

export const GET_TRANSACTIONS_AGG_BY_HOUR = gql `
query GetTransactionaAggByHour {
  aggregatesByHour {
    state
    time
    count
    txn_total
    interventions_total
    ggr_total
  }
}`; */

export const GET_INTERVENTIONS = gql `
query GetInterventions ($startTime: String, $endTime: String) {
  interventions (start_time: $startTime, end_time: $endTime){
    interv_type
    player_id
    state_code
    county_name
    clv
    interv_time
    interv_reason
    session_ggr
    game_name
    country_code
    county_fips
    session_start
    state_name
  }
}`;

export const NEW_INTERVENTIONS = gql`
subscription NewInterventions {
  newInterventions {
    interv_type
    player_id
    state_code
    county_name
    clv
    interv_time
    interv_reason
    session_ggr
    game_name
    country_code
    county_fips
    session_start
    state_name
  }
}
`

export const GET_PLAYER_PROFILE = gql`
query PlayerHistory($playerId: Int!) {
  playerHistory(player_id: $playerId) {
    player_id
    user_name
    first_name
    last_name
    active_session
    archived
    binge_flag
    country_code
    county
    county_fips_code
    email_address
    initial_tenure
    state
    tag
    value_tier
    financial_transactions {
      Type
      Tag
      TimeStamp
      ResultingBalance
      Amount
    }
    wagers {
      Intervention
      Type
      Game
      PlayerBalance
      BetType
      GGRAmount
      Amount
      RiskScore
      Tag
      Id
      Timestamp
    }
    session {
      RiskScore
      EndTriggerWagers
      Wagers
      EndTriggerLosses
      EndingTimestamp
      WagerAmounts
      GGR
      StartTimestamp
      LossAmounts
      EndTriggerBigWin
      Tag
      Id
      StartingBalance
      WinAmounts
      GamesPlayed
      OpeningStakeAmount
      EndTriggerBalance
      BingeFlag
      SessionLength
    }
    game {
      MaximumWager
      Name
      Tag
      MinimumWager
    }
    metrics {
      CLV
      MaxDailySessionTimeRiskScore
      MaxRiskyStakingRiskScore
      MaxSessionTimeThreshold
      ExpectedActiveDaysPerYear
      AvgStake
      MaxDailyLossRiskScore
      LastWagerPlaced
      TotalSessionsTime
      CurrentRiskScore
      NegativeSessions
      Interventions
      AvgWagersPerSession
      AvgSessionsPerDay
      AvgGGRPerSession
      WagerAmounts
      GGR
      MaxSessionTimeRiskScore
      LossAmounts
      MaxSessionHeavyLossThreshold
      HabitualScore
      AvgSessionLength
      Tag
      TotalWagersPlaced
      Deposits
      Withdrawals
      WagersPlacedPerMinute
      CurrentBalance
      WinAmounts
      PositiveSessions
      TotalSessions
      MaxDailyLossThreshold
      MaxRiskyStakingThreshold
      MaxDailySessionTimeThreshold
      MaxSessionHeavyLossRiskScore
    }
  }
}`

export const GET_PLAYER_ID_FROM_EMAIL_ADRESS = gql`
query GetPlayerIdFromEmailAddress($emailAddress: String) {
  getPlayerIdFromEmailAddress(email_address: $emailAddress) {
    player_id
  }
}`

export const GET_INTERVENTIONS_BY_PLAYER_ID = gql`
query InterventionsByPlayerId($playerId: Int!) {
  interventionsByPlayerId(player_id: $playerId) {
    interv_type
    player_id
    state_code
    county_name
    clv
    interv_time
    interv_reason
    session_ggr
    game_name
    country_code
    county_fips
    session_start
    state_name
  }
}
`