import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ApolloClient, InMemoryCache, ApolloProvider, split } from "@apollo/client";
import { HttpLink } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import "mapbox-gl/dist/mapbox-gl.css";

const httpLink = new HttpLink({
  uri: "https://graphql.sparrowdragon.com/graphql",
  cache: new InMemoryCache(),
});

const wsLink = new GraphQLWsLink(createClient({
  url: "wss://graphql.sparrowdragon.com/graphql"
}));

const splitLink = split(({ query }) =>
{
  const { kind, operation } = getMainDefinition(query);
  return (
    kind === "OperationDefinition" &&
    operation === "subscription"
  );
}, wsLink, httpLink)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);
