import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();
  return (
    <React.Fragment>
      {location.pathname === "/" && <Navigate to="/live-transactions" />}
      <Outlet />
    </React.Fragment>
  );
}

export default Layout;
