import React, { useState} from "react";
import { Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";
const LiveTransactions = React.lazy(() =>
  import("../layouts/LiveTransactions")
);

const Transactions = () => {

  const [timeUnit, setTimeUnit] = useState("minute");
  const [refreshSeed, setRefreshSeed] = useState(1);

  const resetComponent = () =>
  {
    setRefreshSeed(Math.random());
  }


  return (
    <Container fluid>
      <Row>
        <Col xs={12} xl={12}>
          <Suspense fallback={<div>Loading...</div>}>
            <LiveTransactions key={refreshSeed} timeUnit={timeUnit} setTimeUnit={setTimeUnit} resetComponent={resetComponent} />
          </Suspense>
        </Col>
      </Row>
    </Container>
  );
};

export default Transactions;
