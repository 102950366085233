import "./IntuitaButton.css";
import FeatherIcon from "feather-icons-react";

const IntuitaButton = (props) => {
  return (
    <button
      name={props.name}
      type={props.type}
      onChange={props.onChange}
      disabled={props.disabled}
      style={{
        ...props.style,
      }}
      id={props.id}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onMouseOver={props.onMouseOver}
      {...props}
      className={`intuita-btn d-flex align-items-center justify-content-center ${props.className}`}
    >
      {" "}
      {props.icon ? <FeatherIcon icon={props.icon} className="me-1" /> : null}
      {props.text}
      {props.children}
    </button>
  );
};

export default IntuitaButton;
