import React, { useState, useEffect } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { commonOptions } from "../shared/Charts/optionsConfig";
import "../../styles/component-style.css";
import { format } from "date-fns";

ChartJS.register(...registerables);

const ProfileRiskTrend = ({ playerData }) => {

  const [playerTimeLabels, setPlayerTimeLabels] = useState([]);
  const [playerMaxScores, setPlayerMaxScores] = useState([]);

  let timeLabelsTemp = [];
  let maxScoresTemp = [];

  useEffect(() => {
    if (playerData.length !== 0 && playerData !== undefined) {
      setUpMaxRiskScores();
    }
  }, []);

  const setUpPlayerWagersAndTransactions = () =>
  {
    let transactionsArray = [];
    playerData.forEach((playerItem) => {
      playerItem.wagers.forEach((wagerItem) => {
        transactionsArray = [...transactionsArray, wagerItem];
      });
    });
    return transactionsArray;
  }

  const setUpMaxRiskScores = () =>
  {
    let playerWagersTransactions = [];
    playerWagersTransactions = setUpPlayerWagersAndTransactions();

    playerWagersTransactions.forEach((item) => {
      let transactionDate = new Date(item.Timestamp);
      let roundedTransactionDate = new Date(`${transactionDate.getMonth()+1}-${transactionDate.getDate()}-${transactionDate.getFullYear()} 00:00:00`);
      let formattedTimeStamp = format(roundedTransactionDate, "LL-dd-yy");
      if (!timeLabelsTemp.includes(formattedTimeStamp)) {
        timeLabelsTemp = [...timeLabelsTemp, formattedTimeStamp];
        maxScoresTemp = [...maxScoresTemp, item.RiskScore];
      }
      else
      {
        let timeStampIndex = timeLabelsTemp.indexOf(formattedTimeStamp);
        if (maxScoresTemp[timeStampIndex] < item.RiskScore) 
        {
          maxScoresTemp[timeStampIndex] = item.RiskScore
        }
      }
    })
    setPlayerTimeLabels(timeLabelsTemp);
    setPlayerMaxScores(maxScoresTemp);
  }

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const backgroundColors = ["#1f8d5980", "#b7ad2680", "#b5101080"];
  const borderColors = ["#1f8d59", "#b7ad26", "#b51010"];

  const options = {
    ...commonOptions,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  /* const thresholdValueFirst = 300;
  const thresholdValueSecond = 600; */

  /* const colorThresholds = (data, colorArray) => {
    if (data.raw < thresholdValueFirst) {
      return colorArray[0];
    } else if (
      data.raw > thresholdValueFirst &&
      data.raw < thresholdValueSecond
    ) {
      return colorArray[1];
    } else {
      return colorArray[2];
    }
  }; */

  const data = {
    playerTimeLabels,
    datasets: [
      {
        fill: true,
        label: "Risk Score",
        data: playerMaxScores,
        borderColor: "#2D9CDB",
        backgroundColor: "#2D9CDB80",
        /* backgroundColor: (data) => colorThresholds(data, backgroundColors),
        borderColor: (data) => colorThresholds(data, borderColors), */
        borderWidth: 2,
      },
    ],
  };

  const fakeData = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Dataset 2",
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        borderColor: "#2D9CDB",
        backgroundColor: "#2D9CDB80",
        /* backgroundColor: (data) => colorThresholds(data, backgroundColors),
        borderColor: (data) => colorThresholds(data, borderColors), */
        borderWidth: 2,
      },
    ],
  };

  return (
    <div style={{ height: "100%", color: "white" }}>
      <div className="component-container" style={{ height: "100%" }}>
        <div style={{ height: "10%" }}>
          <h3 style={{ fontSize: "14px", color: "#a7adcb", fontWeight: "700" }}>
            PROFILE RISK
          </h3>
        </div>
        <div style={{ height: "90%" }}>
          <Bar options={options} data={fakeData} />
        </div>
      </div>
    </div>
  );
};

export default ProfileRiskTrend;
