import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { IoIosInformationCircleOutline as InfoIcon } from "react-icons/io";

const ExtSessionPlayingTime = ({
  configDetails,
  handleChange,
  extPlayTimeMinSoft,
  extPlayTimeMinHard,
  setExtPlayTimeMinSoft,
  setExtPlayTimeMinHard,
  extPlayTimeAvgSessSoft,
  extPlayTimeAvgSessHard,
  setExtPlayTimeAvgSessSoft,
  setExtPlayTimeAvgSessHard,
  extPlayTimeMaxSessSoft,
  setExtPlayTimeMaxSessSoft,
  extPlayTimeMaxSessHard,
  setExtPlayTimeMaxSessHard,
}) => {
  return (
    <div className="ps-3 w-100 card-style">
      {/* Header */}
      <div className="d-flex justify-content-between w-100 position-relative">
        <h4 className="w-50 fw-bold">
          Extended Session Playing Time
          <Tippy
            theme="light"
            arrow={false}
            content="Protect players from binging during a single session |

            Min (mins) - set the minimum amount of time a player can play before triggering an intervention |
            
            Avg - set how many time the player can play above their average before triggering an intervention |
            
            Max - set the maximum amount of time a player can play before triggering an intervention"
          >
            <span>
              <InfoIcon
                size="14"
                color="var(--secondary-blue)"
                className=" position-absolute translate-middle-y"
              />
            </span>
          </Tippy>
        </h4>
      </div>

      {/* Minimum */}
      <div className="d-flex justify-content-between w-100 align-items-center my-1">
        <p
          // style={{ background: "var(--primary-blue)" }}
          // className="mb-0 w-fit-content px-3 py-1"
          className="mb-0 w-50"
        >
          Minimum (minutes)
        </p>
        <div className="d-flex align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeMinSoft"
              value={configDetails.extPlayTimeMinSoft}
              // defaultValue={extPlayTimeMinSoft}
              className="form-control inpt-option"
              onChange={handleChange}
            />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeMinHard"
              value={configDetails.extPlayTimeMinHard}
              // defaultValue={extPlayTimeMinHard}
              className="form-control inpt-option"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {/* Average Session Time Multiplier */}
      <div className="d-flex justify-content-between w-100 align-items-center my-1">
        <p style={{ marginBottom: "0" }} className="w-50">
          Average Session Time Multiplier
        </p>
        <div className="d-flex align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeAvgSessSoft"
              value={configDetails.extPlayTimeAvgSessSoft}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeAvgSessHard"
              value={configDetails.extPlayTimeAvgSessHard}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
        </div>
      </div>

      {/* Maximum */}
      <div className="d-flex justify-content-between w-100 align-items-center mb-3">
        <p style={{ marginBottom: "0" }} className="w-50">
          Maximum (minutes)
        </p>
        <div className="d-flex align-items-center w-50">
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeMaxSessSoft"
              value={configDetails.extPlayTimeMaxSessSoft}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
          <div className="w-50 d-flex justify-content-center">
            <input
              type="number"
              name="extPlayTimeMaxSessHard"
              value={configDetails.extPlayTimeMaxSessHard}
              onChange={handleChange}
              className="form-control inpt-option"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtSessionPlayingTime;
