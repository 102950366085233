import React, { useState, useEffect } from "react";
import InterventionsHeader from "../components/interventions-components/InterventionsHeader";
import InterventionsMainCont from "../components/interventions-components/InterventionsMainCont";
import InterventionsTable from "../components/interventions-components/InterventionsTable";
import LoadingSpin from "react-loading-spin";
import { useQuery as apolloQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { format } from "date-fns";
import { GET_INTERVENTIONS, NEW_INTERVENTIONS } from "../graphql/queries";

const InterventionsLayout = () => {

  const [interventionsTodayDate, setInterventionsTodayDate] = useState("");
  const [timeUnit, setTimeUnit] = useState("minute");
  const [refreshSeed, setRefreshSeed] = useState(1);

  const resetComponent = () =>
  {
    setRefreshSeed(Math.random());
  }

  const setUpInterventionsTableDate = () =>
  {
    let today = new Date();
    let roundedToday = new Date(`${today.getMonth()+1}-${today.getDate()}-${today.getFullYear()} 
    ${today.getHours()}:${today.getMinutes()}:00`);
    let roundedTodayInMiliSec = roundedToday.getTime();
    let lastSixtyValuesMilisecondsInterval = 60 * 60 * 1000;

    let startingDate = new Date(roundedTodayInMiliSec - lastSixtyValuesMilisecondsInterval);
    let roundedDatePart = format(startingDate, "yyyy-LL-dd");
    let roundedHourPart = format(startingDate, "HH:mm:ss.ssssssxxx");
    let completeString = `${roundedDatePart}T${roundedHourPart}`;

    setInterventionsTodayDate(completeString);
    console.log(completeString);
  }

  useEffect(() =>
  {
    setUpInterventionsTableDate();
  },[]);

  const { loading: interventionsLoading, data: interventionsData, error: interventionsError, 
    subscribeToMore: interventionsSubscription } =
    apolloQuery(GET_INTERVENTIONS, { variables:
      {
        startTime: interventionsTodayDate, 
        endTime: "2099-12-31T23:59:59.999999+00:00" ,
      }, fetchPolicy: "network-only"
    });

  return (
    <main className="px-5 py-3">
      <InterventionsHeader timeUnit={timeUnit} setTimeUnit={setTimeUnit} resetComponent={resetComponent} />
      <InterventionsMainCont key={refreshSeed} timeUnit={timeUnit}/>
      {interventionsLoading || !interventionsData ? (
        <div
          className="d-flex flex-column mt-5 align-items-center justify-content-center"
          style={{ height: "45vh" }}
        >
          <h2 className="mb-3" style={{ color: "var(--intuita-main)" }}>
            Fetching data...
          </h2>
          <LoadingSpin size="150px" primaryColor="var(--intuita-main)" />
        </div>
      ) : (
        <InterventionsTable data={interventionsData.interventions} subscription={() =>
        {
          interventionsSubscription({
            document: NEW_INTERVENTIONS,
            updateQuery: (prev, {subscriptionData}) =>
            {
              let prevClone = cloneDeep(prev);
              console.log(prev);
              console.log(subscriptionData);
              if(!subscriptionData.data) return prev;
              const newInterventions = subscriptionData.data.newInterventions;
              return Object.assign({}, prevClone, {
                interventions: [newInterventions, ...prevClone.interventions]
              })
            }
          })
        }} />
      )}
    </main>
  );
};

export default InterventionsLayout;
