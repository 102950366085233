import React from "react";
import ProfileHeader from "../components/profile-components/ProfileHeader";
import ProfileAndRiskInfo from "../components/profile-components/ProfileAndRiskInfo";
import ProfileActivity from "../components/profile-components/ProfileActivity";
import ProfileMain from "../components/profile-components/ProfileMain";
import { useQuery as apolloQuery } from "@apollo/client";
import { GET_INTERVENTIONS_BY_PLAYER_ID, GET_PLAYER_PROFILE } from "../graphql/queries";
import ProfileInterventions from "../components/profile-components/ProfileInterventions";

const UserProfile = ({playerId, playerMailID, setPlayerMailID}) => {

  let playerMainData = null;
  let playerMainLoading = null;
  let playerMainError = null;

  let playerInterventionData = null;
  let playerInterventionLoading = null;
  let playerInterventionError = null;

  if(playerMailID || playerId)
  {
    const { loading: playerLoading, data: playerData, error : playerError } = apolloQuery(GET_PLAYER_PROFILE, {
      variables: {playerId: playerMailID || playerId }, fetchPolicy: "no-cache",
    });
  
    const { loading: playerIntLoading, data: playerIntData, error: playerIntError } = apolloQuery(GET_INTERVENTIONS_BY_PLAYER_ID, {
      variables: {playerId: playerMailID || playerId }, fetchPolicy: "no-cache",
    })
    playerMainData = playerData;
    playerMainLoading = playerLoading;
    playerMainError = playerError;

    playerInterventionData = playerIntData;
    playerInterventionLoading = playerIntLoading;
    playerInterventionError = playerIntError
  }

  return (
    <main className="px-5 py-3">
      <ProfileHeader setPlayerMailID={setPlayerMailID} />
      {!playerMailID && !playerId ? <></> : 
        <>
        {playerMainLoading || !playerMainData  ?
          (
            <>
            {console.log(playerMainError)}
            </>
          ) : 
          <>
            {console.log(playerMainData)}
            <ProfileAndRiskInfo playerData={playerMainData?.playerHistory}/>
            <ProfileMain playerData={playerMainData?.playerHistory}/>
          </>
          }
          {playerInterventionLoading || !playerInterventionData ?
          (
            <>
              {console.log(playerInterventionError)}
            </>
          ) : <ProfileInterventions playerInterventionsData={playerInterventionData?.interventionsByPlayerId}/>
          }
          {playerMainLoading || !playerMainData ? (
            <>
              {console.log(playerMainError)}
            </>
          ) : (
            <ProfileActivity playerData={playerMainData?.playerHistory} />
          )}
        </>
      }
    </main>
  );
};

export default UserProfile;
